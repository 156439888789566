import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'

import Choice from './Choice'

export const getChoices = () => ({
  1: { id: 1, text: `Team () Wins` },
  2: { id: 2, text: `Probst Roast at tribal` },
  3: {
    id: 3,
    text: `Probst Roast person failing in challenge`,
    selected: false,
  },
  4: { id: 4, text: `All _ alliance` },
  5: { id: 5, text: `Silly alliance name` },
  6: {
    id: 6,
    text: `complains about someone not helping with camp`,
    selected: false,
  },
  7: { id: 7, text: `Found a hidden advantage/ idol` },
  8: { id: 8, text: `chicken` },
  9: { id: 9, text: `Bad Weather` },
  10: { id: 10, text: `Split the votes` },
  11: { id: 11, text: `Caught in a lie` },
  12: { id: 12, text: `Water Challenge` },
  13: { id: 13, text: `Endurance Challenge` },
  14: { id: 14, text: `Puzzle challenge` },
  15: { id: 15, text: `No reward challenge Sadge` },
  16: { id: 16, text: `“Easy” vote` },
  17: { id: 17, text: `“We’re going to the end”` },
  18: { id: 18, text: `Smoke Break / Intermission` },
  19: { id: 19, text: `Diesel barks` },
  20: { id: 20, text: `Someone yells fake bingo` },
  21: { id: 21, text: `“I’m terrible at / can’t X”` },
  22: { id: 22, text: `“Trust me”` },
  23: { id: 23, text: `Blindside` },
  24: { id: 24, text: `“Early lead”` },
  25: { id: 25, text: `“What a comeback!”` },
  26: { id: 26, text: `Close up on _ (animal)` },
  27: { id: 27, text: `“First time in survivor history!!!”` },
  28: {
    id: 28,
    text: `One person takes full blame for their teams loss`,
    selected: false,
  },
  29: { id: 29, text: `Sexual innuendo` },
  30: { id: 30, text: `“Target on” _ back` },
  31: { id: 31, text: `Player brags about themselves` },
  32: { id: 32, text: `Player cries` },
  33: { id: 33, text: `“Chopping block”` },
  34: { id: 34, text: `Tree mail` },
  35: { id: 35, text: `Probst wears _ color shirt` },
  36: { id: 36, text: `“Million dollar mistake”` },
  37: { id: 37, text: `Eyeroll` },
  38: { id: 38, text: `Pact made during reward` },
  39: { id: 39, text: `“Under the radar”` },
  40: {
    id: 40,
    text: `Player claims to be the swing vote/ tonights in their hands`,
    selected: false,
  },
  41: {
    id: 41,
    text: `Eavesdropping/overhearing conversations`,
    selected: false,
  },
  42: { id: 42, text: `Scheming at water well/getting wood` },
  43: { id: 43, text: `“Not here to make friends”` },
  44: { id: 44, text: `“Big move”` },
  45: {
    id: 45,
    text: `Individual immunity ruins solid plans `,
    selected: false,
  },
  46: { id: 46, text: `“Worth playing for?”` },
  47: { id: 47, text: `“Drop your buffs” (merge/swap)` },
  48: { id: 48, text: `Unknown twist` },
  49: { id: 49, text: `Product placement` },
})

const useStyles = makeStyles(() => ({
  choices: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > div': {
      alignSelf: 'center',
      width: '13%',
      minWidth: 'min-content',
      flexGrow: 1,
      maxWidth: '20%',
    },
  },
}))

const Choices = (props) => {
  const { choices } = props
  const [selected, setSelected] = useState([])
  const classes = useStyles()

  const onClick = (id) => () => {
    const isSelected = selected.indexOf(id) >= 0
    if (isSelected) {
      //remove from selected
      setSelected([...selected].filter((item) => item.id !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  return (
    <div className={classes.choices}>
      {Object.values(choices)
        .sort((a, b) => a.text.localeCompare(b.text))
        .map((choice) => (
          <Choice
            key={`choice ${choice.text}`}
            choice={choice}
            onClick={onClick(choice.id)}
            selected={selected.indexOf(choice.id) >= 0}
          />
        ))}
    </div>
  )
}

export default Choices
