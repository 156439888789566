import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper } from '@material-ui/core'
import { getDrawerWidth } from '../constants'

const useStyles = makeStyles(() => ({
  boardContainer: {
    minWidth: '30rem',
    marginRight: `${getDrawerWidth()}px`,
    padding: '2em',
  },
  boardInnerContainer: {
    display: 'flex',
    margin: 'auto',
    width: 'min-content',
  },
  board: {
    display: 'grid',
    gridTemplateColumns: '10em 10em 10em 10em 10em  ',
    gridTemplateRows: '10em 10em 10em 10em 10em ',
  },
  cellText: {},
  emptyCellText: {
    color: 'gray',
  },
  cell: {
    border: '1px solid #dbdbdb',
    borderRadius: '0',
    borderRight: 'none',
    borderBottom: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const Cell = (props) => {
  const { text, isEmpty } = props
  const classes = useStyles()
  return (
    <Paper className={classes.cell}>
      <span className={isEmpty ? classes.emptyCellText : classes.cellText}>
        {text}
      </span>
    </Paper>
  )
}

const Board = (props) => {
  const { FreeChoiceElement, choices, selected } = props
  const [choicePosition, setChoicePosition] = useState({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
    10: null,
    11: null,
    12: null,
    13: null,
    14: null,
    15: null,
    16: null,
    17: null,
    18: null,
    19: null,
    20: null,
    21: null,
    22: null,
    23: null,
    24: null,
    25: null,
  })

  const findChoicePosition = (id) => {
    return Object.keys(choicePosition).find((pos) => {
      return pos && pos.id === id
    })
  }

  useEffect(() => {
    // when an option is selected if it isn't on the board add it to the board
    const newChoicePositions = {
      ...choicePosition,
    }
    selected.forEach((choice) => {
      const position = findChoicePosition(choice.id)
      if (position === undefined) {
        const firstEmptyIndex = Object.keys(newChoicePositions).find(
          (choiceKey) => {
            return newChoicePositions[choiceKey] === null
          }
        )
        newChoicePositions[firstEmptyIndex] = choice.id
      }
    })
    console.log('choice added?:', { ...newChoicePositions })
    // when an option is deselected and is on the board remove it from the board
    Object.keys(choicePosition).forEach((positionKey) => {
      const position = choicePosition[positionKey]
      if (position !== null && selected.indexOf(position) === -1) {
        console.log('position:', position)
        // on board but not in selected options
        newChoicePositions[positionKey] = null
      }
    })
    console.log('choice removed?:', { ...newChoicePositions }, selected)
    setChoicePosition(newChoicePositions)
  }, [selected])

  const classes = useStyles()
  const cellElements = []

  for (let i = 0; i < 26; i++) {
    if (i === 12) {
      FreeChoiceElement
        ? cellElements.push(FreeChoiceElement)
        : cellElements.push(<Cell key={`cell ${i}`} index={i} text='FREE' />)
    } else {
      if (choicePosition[i] !== null) {
        console.log('choice on board:', choicePosition[i], choices)
        cellElements.push(
          <Cell
            key={`cell ${i}`}
            index={i}
            text={choices[choicePosition[i]].text}
          />
        )
      } else {
        cellElements.push(<Cell key={`cell ${i}`} index={i} isEmpty text='' />)
      }
    }
  }

  return (
    <div className={classes.boardContainer}>
      <div className={classes.boardInnerContainer}>
        <div className={classes.board}>{cellElements}</div>
      </div>
    </div>
  )
}

export default Board
