import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  Button,
  makeStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Choice from './Choice'
import { getDrawerWidth } from '../constants'

const useStyles = makeStyles(() => ({
  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
const Options = (props) => {
  const {
    choices,
    selected,
    onToggleChoice,
    onDeleteChoice,
    onResetChoices,
    onCreateChoice,
  } = props
  const classes = useStyles()

  const handleSelect = (choice) => {
    onToggleChoice(choice.id)
  }

  return (
    <Drawer
      variant='permanent'
      anchor='right'
      PaperProps={{ style: { width: `${getDrawerWidth()}px` } }}
    >
      <Accordion style={{ margin: '1em', maxHeight: '70%', overflow: 'auto' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Options
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.optionContainer}>
            {Object.values(choices).map((choice) => {
              return (
                <Choice
                  isSelected={selected.indexOf(choice.id) >= 0}
                  choice={choice}
                  onClick={() => handleSelect(choice)}
                />
              )
            })}
          </div>
        </AccordionDetails>
      </Accordion>
      <div style={{ marginTop: 'auto' }}>
        <Button
          variant='contained'
          fullWidth
          color='primary'
          style={{
            margin: '1em',
            width: 'calc(100% - 2em)',
            padding: '0.5em',
            marginBottom: 0,
          }}
        >
          Add Option
        </Button>
        <Button
          variant='contained'
          fullWidth
          color='secondary'
          style={{ margin: '1em', width: 'calc(100% - 2em)', padding: '0.5em' }}
        >
          Reset Options
        </Button>
      </div>
    </Drawer>
  )
  // <Choices />
}

export default Options
