import { Paper, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  choice: {
    margin: '0.5em',
    padding: '0.5em',
    width: 'auto',
  },
}))

const Choice = (props) => {
  const { choice, onClick, isSelected } = props
  const classes = useStyles()

  return (
    <FormGroup>
      <Paper className={classes.choice}>
        <FormControlLabel
          control={<Checkbox checked={isSelected} onChange={onClick} />}
          label={choice.text}
        />
      </Paper>
    </FormGroup>
  )
}

export default Choice
