import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './styles.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import Test from './Test'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      <Route path='/test' exact>
        <Test />
      </Route>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
