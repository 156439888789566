import React, { useState } from 'react'

import Options from './Options'
import Board from './Board'
import { getChoices } from './Options/Choices'

const Test = (props) => {
  const [choices, setChoices] = useState(getChoices())
  const [selected, setSelected] = useState(
    Object.values(choices).map((choice) => choice.id)
  )

  const handleToggleChoice = (id) => {
    if (selected.find((selectedId) => selectedId === id)) {
      setSelected([...selected].filter((selectedId) => selectedId !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const handleDeleteChoice = (id) => {
    const newChoices = { ...choices }
    delete choices[id]
    setChoices(newChoices)
    setSelected(selected.filter((choice) => choice.id === id))
  }

  const handleReset = () => {
    const baseChoices = getChoices()
    setChoices(baseChoices)
    setSelected(Object.values(baseChoices).map((choice) => choice.id))
  }

  const handleCreateChoice = (choice) => {
    setChoices({ ...choices, [choice.id]: choice })
    setSelected([...selected, choice.id])
  }

  return (
    <div>
      <Board choices={choices} selected={selected} />
      <Options
        choices={choices}
        selected={selected}
        onToggleChoice={handleToggleChoice}
        onDeleteChoice={handleDeleteChoice}
        onResetChoices={handleReset}
        onCreateChoice={handleCreateChoice}
      />
    </div>
  )
}

export default Test
